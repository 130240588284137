html, body {
  overscroll-behavior: none;
}

.mainForm{
    width: auto;
    padding: 20px;
}
.verySmallText{
    font-size: 13px;
    line-break: normal;
}
.smallText{
    font-size: 14px;
    line-break: normal;
}

.rowGreen{
  padding-bottom: 5;
  border-width:1;
  border-color: "#FFFFFF";
  background-color:"#C9F5C9";
}

.rowNormal{
  padding-bottom: 5;
  border-width:1;
  border-color: "#FFFFFF";
  background-color:"#CHCHCH";
}
